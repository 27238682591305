@import "common/variables";

.partners {
    .partners-logotypes {
        display: table-row;
        white-space: nowrap;

        img {
            margin: 0 $spacing-default 0 0;
            max-width: 6%;
            max-height: $base-unit*4;

            &:last-child {
                margin: 0;
            }
        }
    }    
}